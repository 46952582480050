import errorHandler from './utils/errorHandler';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  TextField,
  Tab,
  Tabs,
  Snackbar,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem
} from '@mui/material';
import api from './axiosConfig';
import ExpenditureTab from './ExpenditureTab';

const SUPPLIER_TYPES = ['Supplies', 'Services', 'Products'];

const validationSchema = Yup.object({
  type: Yup.string().required('Type is required'),
  supplier_name: Yup.string().required('Supplier name is required'),
  contact_number: Yup.string().required('Contact number is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  address: Yup.string().required('Address is required'),
  vat_number: Yup.string(),
  account_number: Yup.string()
});

const SupplierCard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isNewSupplier, setIsNewSupplier] = useState(false);

  const formik = useFormik({
    initialValues: {
      type: '',
      supplier_name: '',
      description: '',
      vat_applicable: false,
      address: '',
      email: '',
      contact_number: '',
      vat_number: '',
      account_number: ''
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  async function handleSubmit(values, { setSubmitting }) {
    try {
      errorHandler.logApiCall('Submitting supplier data:', values);
      let response;
      if (isNewSupplier) {
        response = await api.post('/suppliers', values);
        setSnackbar({ open: true, message: 'New supplier added successfully', severity: 'success' });
      } else {
        response = await api.put(`/suppliers/${values.id}`, values);
        setSnackbar({ open: true, message: 'Supplier updated successfully', severity: 'success' });
      }
      setIsNewSupplier(false);
      formik.setValues(response.data);
    } catch (error) {
      console.error('Error saving supplier:', error);
      setSnackbar({ 
        open: true, 
        message: error.response?.data?.message || 'Error saving supplier', 
        severity: 'error' 
      });
    } finally {
      setSubmitting(false);
    }
  }

  const handleSearch = async () => {
    try {
      const response = await api.get(`/suppliers/search?name=${searchTerm}`);
      if (response.data && response.data.length > 0) {
        const supplierData = response.data[0];
        formik.setValues({
          id: supplierData.id,
          type: supplierData.type || '',
          supplier_name: supplierData.supplier_name || '',
          description: supplierData.description || '',
          vat_applicable: supplierData.vat_applicable || false,
          address: supplierData.address || '',
          email: supplierData.email || '',
          contact_number: supplierData.contact_number || '',
          vat_number: supplierData.vat_number || '',
          account_number: supplierData.account_number || ''
        });
        setIsNewSupplier(false);
        setSnackbar({ open: true, message: 'Supplier found', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'No supplier found', severity: 'info' });
      }
    } catch (error) {
      console.error('Error searching supplier:', error);
      setSnackbar({ open: true, message: 'Error searching supplier', severity: 'error' });
    }
  };

  const resetForm = () => {
    formik.resetForm();
    setIsNewSupplier(true);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>Supplier Card</Typography>
      
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Search supplier by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button fullWidth variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button fullWidth variant="outlined" onClick={resetForm}>
            New Supplier
          </Button>
        </Grid>
      </Grid>

      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)} 
        sx={{ mb: 3 }}
      >
        <Tab label="Supplier Info" />
        <Tab label="Expenditure" />
      </Tabs>

      {activeTab === 0 ? (
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                name="type"
                label="Type *"
                value={formik.values.type}
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                required
              >
                {SUPPLIER_TYPES.map(type => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="supplier_name"
                label="Supplier Name *"
                value={formik.values.supplier_name}
                onChange={formik.handleChange}
                error={formik.touched.supplier_name && Boolean(formik.errors.supplier_name)}
                helperText={formik.touched.supplier_name && formik.errors.supplier_name}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="description"
                label="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                multiline
                rows={2}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="address"
                label="Address *"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="email"
                label="Email *"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="contact_number"
                label="Contact Number *"
                value={formik.values.contact_number}
                onChange={formik.handleChange}
                error={formik.touched.contact_number && Boolean(formik.errors.contact_number)}
                helperText={formik.touched.contact_number && formik.errors.contact_number}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="vat_number"
                label="VAT Number"
                value={formik.values.vat_number}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="account_number"
                label="Account Number"
                value={formik.values.account_number}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <RadioGroup
                row
                name="vat_applicable"
                value={formik.values.vat_applicable ? "yes" : "no"}
                onChange={(e) => {
                  formik.setFieldValue('vat_applicable', e.target.value === 'yes');
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="VAT Applicable" />
                <FormControlLabel value="no" control={<Radio />} label="VAT Not Applicable" />
              </RadioGroup>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mt: 3 }}>
                <Button type="submit" variant="contained" color="primary">
                  {isNewSupplier ? 'Add Supplier' : 'Update Supplier'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      ) : (
        <ExpenditureTab 
          supplierName={formik.values.supplier_name}
          vatApplicable={formik.values.vat_applicable}
          supplierType={formik.values.type}
        />
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default SupplierCard;