import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Tabs,
  Tab,
  Alert,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import Papa from 'papaparse';

const ExpenditureImport = ({ setMessage, setMessageType }) => {
  const [activeTab, setActiveTab] = useState('INSTRUCTIONS');
  const [validationErrors, setValidationErrors] = useState([]);
  const [importResults, setImportResults] = useState(null);
  
  const VALID_PAYMENT_TYPES = ['CASH', 'CARD', 'BANK'];
  const VALID_CATEGORIES = [
    'Stock',
    'Payroll',
    'Client Comfort',
    'Rent',
    'Rates',
    'Insurance',
    'Heat & Light',
    'IT & Software',
    'Maintenance & Repairs',
    'Training',
    'Office Supplies',
    'Motor Expenses',
    'Telephone',
    'Subscriptions',
    'Accountancy',
    'Bank Charges & Interest',
    'Marketing & Advertising',
    'Furniture & Equipment',
    'Sundry',
    'Expenses'
  ].sort();

  const REQUIRED_FIELDS = [
    'date',
    'supplier_name',
    'total',
    'payment_type',
    'description'
  ];

  const validateDate = (dateStr) => {
    if (!dateStr) return false;
    const ukDateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;  // DD/MM/YYYY format
    return ukDateRegex.test(dateStr);
  };

  const validateAmount = (amount) => {
    if (!amount) return false;
    const num = parseFloat(amount);
    return !isNaN(num) && num >= 0;
  };

  const calculateVatAndNet = (total, vatApplicable) => {
    const totalAmount = parseFloat(total);
    if (vatApplicable) {
      const vat = totalAmount / 6; // VAT at 20%
      const net = totalAmount - vat;
      return {
        vat: vat.toFixed(2),
        net_expenditure: net.toFixed(2)
      };
    }
    return {
      vat: '0.00',
      net_expenditure: totalAmount.toFixed(2)
    };
  };

  const validateExpenditureData = (data) => {
    const errors = [];

    REQUIRED_FIELDS.forEach(field => {
      if (!data[field]) {
        errors.push(`Missing required field: ${field}`);
      }
    });

    if (data.date && !validateDate(data.date)) {
      errors.push('Invalid date format (should be DD/MM/YYYY)');
    }

    if (data.payment_type && !VALID_PAYMENT_TYPES.includes(data.payment_type.toUpperCase())) {
      errors.push(`Invalid payment type. Must be one of: ${VALID_PAYMENT_TYPES.join(', ')}`);
    }

    if (data.category && !VALID_CATEGORIES.includes(data.category)) {
      errors.push('Invalid category');
    }

    if (data.total && !validateAmount(data.total)) {
      errors.push('Total amount must be a positive number');
    }

    return errors;
  };

  const processExpenditureData = async (data) => {
    try {
      // Convert date from DD/MM/YYYY to YYYY-MM-DD for database
      const [day, month, year] = data.date.split('/');
      const formattedDate = `${year}-${month}-${day}`;

      // Get supplier VAT status
      const supplierResponse = await window.fetch(`/api/suppliers/${encodeURIComponent(data.supplier_name)}`);
      if (!supplierResponse.ok) {
        throw new Error(`Supplier not found: ${data.supplier_name}`);
      }
      const supplierData = await supplierResponse.json();
      const vatApplicable = supplierData.vat_applicable;

      // Calculate VAT and net amounts
      const { vat, net_expenditure } = calculateVatAndNet(data.total, vatApplicable);

      return {
        ...data,
        date: formattedDate,
        payment_type: data.payment_type.toUpperCase(),
        vat,
        net_expenditure
      };
    } catch (error) {
      throw new Error(`Error processing expenditure: ${error.message}`);
    }
  };

  const handleDownloadTemplate = () => {
    const templateContent = 'date,supplier_name,category,description,total,payment_type\n' +
      '01/02/2024,ABC Supplies Ltd,Office Supplies,Printer Paper,120.00,CARD\n' +
      '01/02/2024,XYZ Services,Training,Staff Training Course,500.00,BANK';

    const blob = new Blob([templateContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'expenditure_import_template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setValidationErrors([]);
    setImportResults(null);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const allErrors = [];
        const validData = [];
        let totalVat = 0;
        let totalNet = 0;

        for (let index = 0; index < results.data.length; index++) {
          const row = results.data[index];
          
          if (Object.values(row).every(value => !value)) {
            continue;
          }

          const rowErrors = validateExpenditureData(row);
          if (rowErrors.length > 0) {
            allErrors.push(`Row ${index + 1}: ${rowErrors.join(', ')}`);
          } else {
            try {
              const processedRow = await processExpenditureData(row);
              totalVat += parseFloat(processedRow.vat);
              totalNet += parseFloat(processedRow.net_expenditure);
              validData.push(processedRow);
            } catch (error) {
              allErrors.push(`Row ${index + 1}: ${error.message}`);
            }
          }
        }

        if (allErrors.length > 0) {
          setValidationErrors(allErrors);
          setMessage('Validation errors found. Please check the errors below and try again.');
          setMessageType('error');
          return;
        }

        try {
          const response = await window.fetch('/api/expenditures/import', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(validData),
          });

          const results = await response.json();
          
          setImportResults({
            ...results,
            totalVat: totalVat.toFixed(2),
            totalNet: totalNet.toFixed(2)
          });

          if (results.successful_imports === 0) {
            setMessageType('error');
            if (results.errors && results.errors.length > 0) {
              setValidationErrors(results.errors);
              setMessage('Import failed. Please check the errors below.');
            } else {
              setMessage('Import failed. No expenditures were imported.');
            }
          } else {
            if (results.failed_imports > 0) {
              setMessageType('warning');
              setMessage(`Imported ${results.successful_imports} expenditures with ${results.failed_imports} failures.`);
              if (results.errors) {
                setValidationErrors(results.errors);
              }
            } else {
              setMessageType('success');
              setMessage(`Successfully imported ${results.successful_imports} expenditures!`);
            }
          }

          event.target.value = '';

        } catch (error) {
          console.error('Error uploading file:', error);
          setMessageType('error');
          setMessage('Error importing expenditures. Please try again.');
        }
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        setMessage('Error reading CSV file. Please check the format and try again.');
        setMessageType('error');
      }
    });
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Expenditure Import</Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <Tab label="Import Instructions" value="INSTRUCTIONS" />
          <Tab label="Import" value="IMPORT" />
        </Tabs>
      </Box>

      {activeTab === 'INSTRUCTIONS' && (
        <Box>
          <Typography variant="h6" gutterBottom>Before You Import</Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Required Fields:</Typography>
            <ul>
              <li>Date (DD/MM/YYYY format)</li>
              <li>Supplier Name (must match existing supplier)</li>
              <li>Description</li>
              <li>Total Amount (including VAT if applicable)</li>
              <li>Payment Type</li>
            </ul>
          </Alert>

          <Alert severity="warning" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Payment Types:</Typography>
            <ul>
              {VALID_PAYMENT_TYPES.map(type => (
                <li key={type}>{type}</li>
              ))}
            </ul>
          </Alert>

          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Categories:</Typography>
            <Box sx={{ maxHeight: '200px', overflowY: 'auto' }}>
              <ul>
                {VALID_CATEGORIES.map(category => (
                  <li key={category}>{category}</li>
                ))}
              </ul>
            </Box>
          </Alert>

          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">VAT Handling:</Typography>
            <ul>
              <li>VAT will be automatically calculated based on supplier VAT status</li>
              <li>Enter the total amount including VAT where applicable</li>
              <li>The system will calculate VAT (20%) and net amounts automatically</li>
            </ul>
          </Alert>
        </Box>
      )}

      {activeTab === 'IMPORT' && (
        <Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>Import Instructions</Typography>
            <Alert severity="info" sx={{ mb: 3 }}>
              <Typography variant="body2">
                1. Download the template below
                <br />
                2. Fill in your expenditure data following the format requirements
                <br />
                3. Save and upload your completed file
                <br />
                All required fields must be completed.
              </Typography>
            </Alert>

            {validationErrors.length > 0 && (
              <Alert severity="error" sx={{ mb: 3 }}>
                <Typography variant="subtitle2">Validation Errors:</Typography>
                <ul>
                  {validationErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </Alert>
            )}

            {importResults && (
              <Alert 
                severity={importResults.successful_imports > 0 ? 
                  (importResults.failed_imports > 0 ? 'warning' : 'success') : 
                  'error'
                } 
                sx={{ mb: 3 }}
              >
                <Typography variant="subtitle2">Import Results:</Typography>
                <ul>
                  <li>Successfully imported: {importResults.successful_imports}</li>
                  {importResults.failed_imports > 0 && (
                    <li>Failed imports: {importResults.failed_imports}</li>
                  )}
                  <li>Total VAT: £{importResults.totalVat}</li>
                  <li>Total Net: £{importResults.totalNet}</li>
                </ul>
              </Alert>
            )}

            <Button
              startIcon={<DownloadIcon />}
              variant="outlined"
              onClick={handleDownloadTemplate}
              sx={{ mt: 2, mb: 4 }}
            >
              Download Template
            </Button>

            <Box>
              <Typography variant="subtitle1" gutterBottom>Import Data</Typography>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                id="csv-file-input"
              />
              <label htmlFor="csv-file-input">
                <Button
                  startIcon={<UploadIcon />}
                  variant="contained"
                  component="span"
                >
                  Choose CSV File
                </Button>
              </label>
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default ExpenditureImport;